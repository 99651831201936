const getIndexesOfValueInArray = (array, value) => {
  let indexesOfValueInArray = []
  for(let index in array) {
     if (array[index] === value){
      indexesOfValueInArray.push(index);
     }
  }
  return indexesOfValueInArray;
}

export const calculateAveragePerWaterYear = (uniqueMonthAndDay, monthAndDayDates, values) => {
    let averageValue = [];
    for (let MonthAndDay of uniqueMonthAndDay) {
        let indexesOfValueInArray = getIndexesOfValueInArray(monthAndDayDates, MonthAndDay);
        let valuesTmpArray = [];
        indexesOfValueInArray.forEach( indexOfValue => {
          valuesTmpArray.push(values[indexOfValue]);
        })
        averageValue.push(average(valuesTmpArray));
    }
    return averageValue;
}

// In %Y-%m-%d format
export const getMonthAndDayFromStrDate = (date) =>   {
    const [year, month, day] = date.split("-");
    return `${month}-${day}`;
}

export const setWaterYearToDates = (dates, startWaterYear) => {

    const referenceDateParts = startWaterYear.split('-').map(Number);
    const referenceMonth = referenceDateParts[0];
    const referenceDay = referenceDateParts[1];
  
    let adjustedDates = [];
    dates.forEach(date => {
        const dateParts = date.split('-').map(Number);
        const dateMonth = dateParts[0];
        const dateDay = dateParts[1];
  
        let adjustedYear;
        if (dateMonth < referenceMonth || (dateMonth === referenceMonth && dateDay < referenceDay)) {
            adjustedYear = 2004;
        } else {
            adjustedYear = 2003;
        }
        adjustedDates.push(`${adjustedYear}-${date}`);
    });
    return adjustedDates;
  
  }
  
export const sortAdjustedDates = (adjustedDates) => {
    const adjustedDatesArr = [...adjustedDates];
    return adjustedDatesArr.sort((a, b) => {
        return new Date(a) - new Date(b);
    });
  }
  
export const sortValuesByDates = (dates, sortedAdjustedDates, values) =>{
    return sortedAdjustedDates.map(( originalDate ) => {
        const index = dates.indexOf(originalDate);
        return values[index];
    });
  }

export const averageDataToPlotyFormat = (listOfTotalDatesAvailable, averageValuesPerTotalDatesAvailable, startYearForAverageCalculation, endYearForAverageCalculation, availableWaterYears ) => {
    let traces = [];

    const availableWaterYearsSorted = [...availableWaterYears];
    availableWaterYearsSorted.sort((a, b) => a - b);
    
    let trace_avg = {
      x: listOfTotalDatesAvailable,
      y: averageValuesPerTotalDatesAvailable,
      name: 'Avg ' + startYearForAverageCalculation + ' - ' + (availableWaterYearsSorted[availableWaterYearsSorted.length - 1] === endYearForAverageCalculation ? 'Now' : endYearForAverageCalculation),
      type: 'scatter',
      mode: 'lines',
      line: {
        width: 2,
        dash: 'dot',
      },
      marker: { 
        color: 'rgb(169,169,169)' 
      },
      legendgroup: 'average',
    }
        traces.push(trace_avg);
   return traces
  }

export const filterDatesAndValues = (startYearForAverageCalculation, endYearForAverageCalculation, targetMonthDay, datesArray, valuesArray) => {
  
    // Parse target month and day
    const [targetMonth, targetDay] = targetMonthDay.split('-').map(Number);

    // // Calculate start and end dates for comparison
    const startDateObj = new Date(`${startYearForAverageCalculation}-${targetMonth}-${targetDay}`);
    const endDateObj = new Date(`${endYearForAverageCalculation}-${targetMonth}-${targetDay}`);
    endDateObj.setMonth(endDateObj.getMonth() - 1); // Set to last day of previous month

    // Filter dates and values within the specified range
    const filteredDates = [];
    const filteredValues = [];
    datesArray.forEach((dateStr, index) => {
        const [year, month, day] = dateStr.split('-').map(Number);
        const currentDate = new Date(year, month - 1, day);

        // Check if the date is within the specified range
        if (currentDate >= startDateObj && currentDate < endDateObj) {
            filteredDates.push(dateStr);
            filteredValues.push(valuesArray[index]);
        }
    });

  return [filteredDates, filteredValues];
}

const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;