const getIndexesOfValueInArray = (array, value) => {
    let indexesOfValueInArray = []
    for(let index in array) {
       if (array[index] === value){
        indexesOfValueInArray.push(index);
       }
    }
    return indexesOfValueInArray;
  }

  
export const calculateQuantilePerWaterYear = (uniqueMonthAndDay, monthAndDayDates, values, quantile) => {
    let quantileValue = [];
    for (let MonthAndDay of uniqueMonthAndDay) {
        let indexesOfValueInArray = getIndexesOfValueInArray(monthAndDayDates, MonthAndDay);
        let valuesTmpArray = [];
        indexesOfValueInArray.forEach( indexOfValue => {
          valuesTmpArray.push(values[indexOfValue]);
        })
        quantileValue.push(quantile_calc(valuesTmpArray, quantile));
    }
    return quantileValue;
}

const quantile_calc = (values, quantile) => {
      const sorted = values.sort((a, b) => a - b);
      const pos = (sorted.length - 1) * quantile;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
          return sorted[base];
      }
}

export const quantileDataToPlotyFormat = (listOfTotalDatesAvailable, uniqueMonthAndDay, monthAndDayDates, avgValues) => {
    const quantile075 = calculateQuantilePerWaterYear(uniqueMonthAndDay, monthAndDayDates, avgValues, 0.75)
    const quantile025 = calculateQuantilePerWaterYear(uniqueMonthAndDay, monthAndDayDates, avgValues, 0.25)
    const quantile095 = calculateQuantilePerWaterYear(uniqueMonthAndDay, monthAndDayDates, avgValues, 0.95)
    const quantile005 = calculateQuantilePerWaterYear(uniqueMonthAndDay, monthAndDayDates, avgValues, 0.05)

    const traces = [
        {
            x: listOfTotalDatesAvailable,
            y: quantile075,
            type: 'scatter',
            mode: 'lines',
            line: { color: 'rgba(0,0,0,0)' },
            name: '',
            showlegend: false,
            hoverinfo : 'none',
            legendgroup: 'quantile',
        },
        {
            x: listOfTotalDatesAvailable,
            y: quantile025,
            type: 'scatter',
            mode: 'lines',
            line: { color: 'rgba(0,0,0,0)' },
            fill: 'tonexty',
            fillcolor: 'rgba(173, 216, 230, 0.5)', // lightblue, alpha=0.5
            name: 'IQR (25th - 75th)',
            hoverinfo : 'none',
            legendgroup: 'quantile',
        },
        {
            x: listOfTotalDatesAvailable,
            y: quantile095,
            type: 'scatter',
            mode: 'lines',
            line: { color: 'rgba(0,0,0,0)' },
            name: '',
            showlegend: false,
            hoverinfo : 'none',
            legendgroup: 'quantile',
        },
        {
            x: listOfTotalDatesAvailable,
            y: quantile005,
            type: 'scatter',
            mode: 'lines',
            line: { color: 'rgba(0,0,0,0)' },
            fill: 'tonexty',
            fillcolor: 'rgba(173, 216, 230, 0.2)', // lightblue, alpha=0.2
            name: 'IQR (5th - 95th)',
            hoverinfo : 'none',
            legendgroup: 'quantile',
        },
    ]
    return traces
  }