// External
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

// Internal
import { graphConfiguration, graphLayout, YearlyGraphDataManager } from './../utils/yearly_conf';
import { fuseDatesAndValuesInSingleYear } from './../utils/yearly_aux';
import { 
  calculateAveragePerWaterYear, 
  getMonthAndDayFromStrDate, 
  setWaterYearToDates, 
  sortAdjustedDates, 
  sortValuesByDates, 
  averageDataToPlotyFormat, 
  filterDatesAndValues 
} from './../utils/yearly_average_calculation_aux';
import {quantileDataToPlotyFormat} from './../utils/yearly_quantile_calculation_aux';

function YearlyGraphPlot ({ 
  startYearForAverageCalculation, 
  endYearForAverageCalculation, 
  waterYearStart, 
  availableDates, 
  availableValues, 
  productName, 
  availableWaterYears
}) {

  /**
   * Create the data format for the ploty graph for all available data.
   */

  const [availableData, setAvailableData] = useState([{}])

    useEffect(()=> {
      const fusedAvailableData = fuseDatesAndValuesInSingleYear(availableDates, availableValues, availableWaterYears, waterYearStart);
      setAvailableData(YearlyGraphDataManager.selectedDataToPlotyFormat(availableWaterYears, fusedAvailableData, availableWaterYears.length, productName));  
    }, [availableWaterYears])

  /**
   * Create the data format for the ploty graph for the average of the selected dates.
   */

  let [avgDates, avgValues] = [[], []];

  if (startYearForAverageCalculation & endYearForAverageCalculation){
    [avgDates, avgValues] = filterDatesAndValues(startYearForAverageCalculation, endYearForAverageCalculation, waterYearStart, availableDates, availableValues);
  }

  const monthAndDayDates = avgDates.map(date => getMonthAndDayFromStrDate(date));
  const uniqueMonthAndDay = Array.from(new Set(monthAndDayDates)).filter(date => date !== '02-29'); // Remove February 29 for the avg calculation
  const averageValues = calculateAveragePerWaterYear(uniqueMonthAndDay, monthAndDayDates, avgValues);
  const averageValuesRounded = averageValues.map(value => Math.round((value + Number.EPSILON) * 100) / 100);
  const datesWithWaterYear = setWaterYearToDates(uniqueMonthAndDay, waterYearStart);
  const sortedDates = sortAdjustedDates(datesWithWaterYear);
  const sortedAverageValuesRounded = sortValuesByDates(datesWithWaterYear, sortedDates, averageValuesRounded);
  const avgDataPlot = averageDataToPlotyFormat(sortedDates, sortedAverageValuesRounded, startYearForAverageCalculation, endYearForAverageCalculation, availableWaterYears);
  let graph_data = [];
  // use this if to filter which products will receive the quantile plot
  // if (['swe', 'swevolume', 'hs'].includes(productName.toLowerCase())) {
  //   const quantileDataPlot = quantileDataToPlotyFormat(sortedDates, uniqueMonthAndDay, monthAndDayDates, avgValues);
  //   graph_data = [...quantileDataPlot,...availableData, ...avgDataPlot,];
  // } else {
  //   graph_data = [...availableData, ...avgDataPlot,];
  // }
  const quantileDataPlot = quantileDataToPlotyFormat(sortedDates, uniqueMonthAndDay, monthAndDayDates, avgValues);
  graph_data = [...quantileDataPlot,...availableData, ...avgDataPlot,]; 

      return(
          <Plot
            className='me-3'
            divId='yearlyGraph'
            data={graph_data}
            layout={graphLayout(productName)}
            config={graphConfiguration()}
          />
      )
}

export default YearlyGraphPlot;